import Link from 'next/link'

const NotFound = () => {
  return (
    <main className="flex min-h-screen w-screen flex-col items-center justify-center gap-10">
      <div className="flex flex-col items-center justify-center">
        <h1
          style={{ WebkitTextStroke: '4px rgba(39, 39, 42, 1)' }}
          className="text-[120px] font-black leading-none text-transparent md:text-[160px] lg:text-[240px]"
        >
          404
        </h1>
        <p className="text-2xl font-semibold sm:text-3xl md:text-4xl">
          NOT FOUND
        </p>
      </div>

      <Link
        href="/"
        className="group flex items-center justify-center gap-4 rounded-full bg-brand-600 px-4 py-2 text-center text-lg text-slate-50 duration-200 hover:bg-brand-500 focus:bg-brand-500 active:bg-brand-700 md:px-8 md:py-4"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 6.5H1.5M1.5 6.5L6.63508 1.5M1.5 6.5L6.63508 11.5"
            stroke="#FEFEFE"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        Go back to Home page
      </Link>
      <img
        src="/bg-gradient.svg"
        alt="background"
        className="pointer-events-none absolute inset-0 h-full w-full object-cover transition-all duration-300"
      />
    </main>
  )
}

export default NotFound
